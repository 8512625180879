import domReady from '@roots/sage/client/dom-ready';


/**
 * Application entrypoint
 */
domReady(async () => {
  // ...
  const button = document.querySelector('#menu_button');
  const menu = document.querySelector('#header_menu');
  const menu_item = document.querySelector('.mega-menu-item');

  setTimeout(function () {
    jQuery(document).on('mouseover', '.mega-menu-item', function () {
      jQuery(this).find('.mega-sub-menu').addClass('mega-menu-show');
    });

    jQuery(document).on('mouseout', '.mega-menu-item', function () {
      jQuery(this).find('.mega-sub-menu').removeClass('mega-menu-show');
    });

    jQuery(document).ready(function () {
      jQuery('.mega-sub-menu').each(function (index) {
        if (jQuery(this).html() == '') {
          jQuery(this).closest('.mega-menu-column').remove();
        }
      });
    });

    jQuery(document).on('click', '.open-mega-menu', function () {
      jQuery(this).closest('.mega-menu-item').find('.mega-sub-menu').toggleClass('mega-menu-show');
    });
  }, 500);

  if (button !== null) {
    button.addEventListener('click', () => {
      menu.classList.toggle('hidden');
    });
  }
  document.addEventListener('click', (event) => {
    const isClickInsideMenu = menu.contains(event.target) || button.contains(event.target);
    if (!isClickInsideMenu) {
      menu.classList.add('hidden');
    }
  });

  // Get references to the body and menu elements
  const body = document.body;
  const menuToggle = document.getElementById('menu_button');

  // Add click event listener to the menu toggle button
  menuToggle.addEventListener('click', function () {
    // Toggle a CSS class on the body element
    body.classList.toggle('menu-open');

    // You can also toggle additional styles on the menu itself if needed
    menu.classList.toggle('open');
  });
  document.body.addEventListener('click', function (event) {
    // Check if the clicked element is not part of the menu
    if (!menu.contains(event.target) && !menuToggle.contains(event.target)) {
      // Remove the class from the body
      body.classList.remove('menu-open');
    }
  });


  const searchIcon = document.querySelector('.js__search');
  const searchBox = document.querySelector('.js__searchbox');

  if (searchIcon !== null && searchBox !== null) {
    searchIcon.addEventListener('click', () => {
      searchBox.classList.toggle('active');
      searchIcon.classList.toggle('active');
    });
  }

  const search_desktop = document.querySelector('.js__search_desktop');
  const searchbox_desktop = document.querySelector('.js__searchbox_desktop');

  if (search_desktop !== null && searchbox_desktop !== null) {
    search_desktop.addEventListener('click', () => {
      searchbox_desktop.classList.toggle('active');
      search_desktop.classList.toggle('active');
    });
  }
});
jQuery(window).scroll(function () {
  if (jQuery(this).scrollTop() > 1) {
    jQuery('.header').addClass("sticky_header");
  } else {
    jQuery('.header').removeClass("sticky_header");
  }
});

// Footer Popup
if (window.innerWidth < 768) {
  document.addEventListener('DOMContentLoaded', function () {
    var enquiryButtonWrapper = document.querySelector('.contact-button-wrapper');
    var footer = document.querySelector('.footer');

    window.addEventListener('scroll', function () {
      var footerPosition = footer.getBoundingClientRect();

      if (footerPosition.top <= window.innerHeight) {
        enquiryButtonWrapper.style.display = 'none';
      } else {
        enquiryButtonWrapper.style.display = 'block';
      }
    });
  });

  document.addEventListener('readystatechange', function () {
    if (document.readyState === 'complete') {
      var enquiryButtonWrapper = document.querySelector('.contact-button-wrapper');

      if (enquiryButtonWrapper) {
        var stickypopup = enquiryButtonWrapper;
        stickypopup.addEventListener('click', function (e) {
          e.preventDefault();
          stickypopup.parentNode.classList.add('new-contact-form');
        });

        var closeicon = document.querySelector('.close-icons');
        closeicon.addEventListener('click', function (s) {
          s.preventDefault();
          closeicon.parentNode.parentNode.parentNode.classList.remove('new-contact-form');
        });
      }
    }
  });
}

// Popup Code
if (window.innerWidth < 768) {
  var popupContainer = document.getElementById('popup-container');
  var popup = popupContainer.querySelector('.close-icons');

  var enquiryButtonWrappers = document.querySelectorAll('.contact-button-wrapper');
  enquiryButtonWrappers.forEach(function (enquiryButtonWrapper) {
    enquiryButtonWrapper.onclick = function () {
      popupContainer.style.display = 'block';

      setTimeout(function () {
        popupContainer.style.opacity = 1;
      }, 100);

      document.querySelector('html').classList.add('scroll-hide');
      document.querySelector('body').classList.add('hide-two-popup');
    };
  });

  popup.onclick = function () {
    window.location.reload();

    popupContainer.style.display = 'none';
    setTimeout(function () {
      popupContainer.style.opacity = 0;
    }, 900);

    document.querySelector('html').classList.remove('scroll-hide');
    document.querySelector('body').classList.remove('hide-two-popup');
  };
}


/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */

import.meta.webpackHot?.accept(console.error);

